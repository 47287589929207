import { Link } from "react-router-dom";
import Box from "@carrier-io/air-react/Box";
import { LynxLogoIcon } from "./LynxLogoIcon";
import "./styles.scss";

export const NavbarLeft = (): JSX.Element => (
  <Box display="flex">
    <Link to="/">
      <LynxLogoIcon sx={{ width: "32px", height: "32px" }} fill="white" />
    </Link>
  </Box>
);
