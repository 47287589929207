import React from "react";
import { Box } from "@mui/material";
import { Header, SubHeading } from "../../../components";
import ContentCard from "../../../components/ContentCard/ContentCard";
import { apiCardsDetail } from "./ApiCardDetails";

import {
  carrierLogo,
  StaticImage,
  BackgroundImage,
  SubcontentImg,
  SubcontentImg2,
  LynxLogo,
} from "../assets";

import "./HomePage.scss";

const HomePage: React.FC = () => {
  const subContent = [
    {
      iconUrl: SubcontentImg2,
      heading: "Simple Setup",
      paragraph:
        "Lörem ipsum antirade. Des jevir reahiskade. Antivalens govav. Oläsk vissa homoktiga espeng innan antipong. Fall hemibel om revidade.",
    },
    {
      iconUrl: SubcontentImg,
      heading: "Made for Developers",
      paragraph:
        "Lörem ipsum kuken reangar terade synism. Eument fydälogi att ät, fanat, vasade.",
    },
  ];

  return (
    <>
      <Box className="landing-container">
        <Header
          logo={<img src={carrierLogo} alt="Logo" />}
          mainHeading="Code to Connect"
          mainParagraph="Carrier’s Transport Refrigeration API Solutions provides exceptional experiences across Business Units."
          buttonText="Let's get started"
          subContent={subContent}
          headerStaticImage={StaticImage}
          headerBgImage={BackgroundImage}
        />
        <SubHeading logo={LynxLogo} heading="Explore LYNX API Products" />
        <Box className="api-products">
          {apiCardsDetail.map((card, index) => (
            <ContentCard
              key={index}
              title={card.title}
              imageUrl={card.imageUrl}
              subtitle={card.subtitle}
              description={card.description}
              buttonText={card.buttonText}
              iconUrl={card.iconUrl}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
