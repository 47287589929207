import React from "react";
import { AppBar, Toolbar, Button, Typography, Box } from "@mui/material";
import { HeaderProps } from "../types";

const Header: React.FC<HeaderProps> = ({ onAuthorizeClick }) => {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#011666",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginLeft: "10px" }}
        >
          Carrier LYNX Developer APIs
        </Typography>
        <Box sx={{ display: "flex", gap: 2, fontWeight: "bold" }}>
          <Button
            color="inherit"
            onClick={onAuthorizeClick}
            sx={{
              fontWeight: "bold",
              backgroundColor: "white",
              color: "black",
            }}
          >
            Authorize
          </Button>
          <Button
            color="inherit"
            onClick={onAuthorizeClick}
            sx={{
              fontWeight: "bold",
              backgroundColor: "white",
              color: "black",
            }}
          >
            Download Spec
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
