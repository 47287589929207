import React from "react";
import { Typography } from "@carrier-io/air-react";

interface circleProp {
  cardIndex?: number;
}

const CardIndexCircle: React.FC<circleProp> = ({ cardIndex }) => {
  return (
    <Typography
      component="span"
      variant="body1"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 32,
        height: 32,
        borderRadius: "50%",
        backgroundColor: "#1367C1",
        color: "white",
      }}
    >
      {cardIndex}{" "}
    </Typography>
  );
};

export default CardIndexCircle;
