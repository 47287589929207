import Box from "@carrier-io/air-react/Box";
import Tag from "@carrier-io/air-react/Tag";
import { Typography } from "@mui/material";
import "./styles.scss";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@carrier-io/air-react/Button";
import { CloudSyncOutlined } from "@mui/icons-material";
import ApiTileBg from "./ApiTileBg.svg";
const ProductPage = () => {
  return (
    <>
      <Box className="product-page">
        <Box className="pageTitle">
          <Typography variant="h5" width={960} height={49}>
            Develop with LYNX APIs
          </Typography>
          <Typography variant="h6" width={960} height={36}>
            {" "}
            LYNX API Services allow you to innovate, connect and create smart
            building solutions. Each LYNX API product has been designed and
            packaged for specific use and consist of more than one API. Check
            out our offering below to discover what you can do with LYNX APIs
            and how they work.
          </Typography>
        </Box>
        <Box className="tiles">
          <Box className="row">
            <Typography variant="subtitle1" width={960}>
              TTI Business APIs
            </Typography>
            <Button
              className="api-tile"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "unset",
                padding: 0,
              }}
            >
              <Box
                className="api-tile-subscribed"
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  paddingTop: "8px",
                  paddingRight: "8px",
                }}
              >
                <Tag
                  startIcon={<CheckIcon />}
                  variant="filled"
                  label="Subscribed"
                ></Tag>
              </Box>
              <Box className="api-tile-label">
                External Rest API
                <CloudSyncOutlined />
                <Tag
                  variant="outlined"
                  label="TTI Business"
                  className="api-tile-label-tag"
                />
              </Box>
              <img className="api-tile-label-bg" src={ApiTileBg} />
            </Button>
          </Box>
        </Box>
        <Box className="pageTitle"></Box>
      </Box>
    </>
  );
};

export default ProductPage;
