import Avatar from '@carrier-io/air-react/Avatar';
import Button from '@carrier-io/air-react/Button';
import { useState } from 'react';
import ContactForm from '../ContactForm/ContactForm';


export const NavbarRight = (): JSX.Element => {
    const [isOpen,setOpen] = useState(false)

    const hardcodeName = "Test Developer";
    const handleOpen = () => {
        setOpen(true)
    }
    
    const handleClose = () => {
        setOpen(false)
    }

    return(
        <>
        <Button variant='text' sx={{color:"white"}} onClick={handleOpen} disableRipple>
             Contact Us
        </Button>
        <ContactForm open={isOpen} onClose={handleClose}/>
        <Button
                variant="text"
                color="secondary"
                disableTouchRipple
                sx={{ fontWeight: 600, py: 2, pr: 2 }}
                data-testid="user-profile"
            >
                {/* <Typography
                variant="subtitle2"
                sx={{
                    color: 'rgba(255, 255, 255, 0.6)',
                }}
                >
                Hi,&nbsp;
                </Typography>
                <Typography
                variant="subtitle2"
                sx={{
                    color: 'rgba(255, 255, 255, 0.87)',
                }}
                >
                <span data-private>{hardcodeName}</span>
                </Typography> */}
                <Avatar
                style={{
                    background: '#c9f7f5',
                    color: '#1bc5bd',
                    marginLeft: '0.75rem',
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                }}
                sx={{ fontSize: 'subtitle1.fontSize' }}
                >
                {hardcodeName[0] ?? ''}
                </Avatar>
            </Button>
            </>
    );
};
