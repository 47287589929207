import React, { useState } from "react";
import Footer from "../../../components/Footer/Footer";
import HomePage from "../HomePage/HomePage";
import GettingStarted from "../GettingStarted/GettingStarted";
import Navbar from "../../../components/Navbar/Navbar";
import ProductPage from "../../ProductPage/ProductPage";

const LandingPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState("home");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "home":
        return <HomePage />;
      case "getting-started":
        return <GettingStarted />;
      case "api-portal":
        return <ProductPage />;
    }
  };
  return (
    <>
      <Navbar activeTab={activeTab} handleTabChange={handleTabChange} />
      <div>{renderTabContent()}</div>
      <Footer />
    </>
  );
};

export default LandingPage;
