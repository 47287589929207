import React from "react";
import Box from "@carrier-io/air-react/Box";
import logo from "../../features/LandingPage/assets/Oval.svg";
import "./Footer.scss";

const Footer = () => {
  return (
    <Box className="footer">
      <Box className="logo">
        <img src={logo}></img>
        <Box> @ 2024 Carrier </Box>
      </Box>
      <Box className="legal">
        <Box> Privacy Statement </Box>
        <Box> Terms & Conditions</Box>
        <Box> Contact </Box>
      </Box>
    </Box>
  );
};

export default Footer;
