import React from "react";
import { Tab } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import Tabs from "@carrier-io/air-react/Tabs";
import AppBarTopHeader from "@carrier-io/air-react/patterns/AppBarTopHeader";
import { NavbarLeft } from "./NavbarLeft";
import { NavbarRight } from "./NavbarRight";
import Container from "@carrier-io/air-react/Container";
import { PageLoader } from "../PageLoader/PageLoader";
import "./styles.scss";

interface NavbarProps {
  activeTab: string;
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
}
const Navbar: React.FC<NavbarProps> = ({ activeTab, handleTabChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        handleClose();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <Container className="navbar">
      <PageLoader className="header__progress-bar" />
      <AppBarTopHeader
        appBarProps={{
          sx: {
            boxShadow: "none",
          },
        }}
        toolbarProps={{
          disableGutters: true,
          variant: "dense",
          sx: {
            backgroundColor: "#001665",
          },
        }}
        leftJustifiedGridProps={{ className: "app-bar-left" }}
        centralGridProps={{ className: "app-bar-central" }}
        rightJustifiedGridProps={{ className: "app-bar-right" }}
        leftJustifiedComponent={<NavbarLeft />}
        centralComponent={
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Home" value="home" disableRipple={true} />
            <Tab
              label="Getting Started"
              value="getting-started"
              disableRipple={true}
            />
            <Tab
              label="Lynx API Portal"
              value="api-portal"
              disableRipple={true}
            />
          </Tabs>
        }
        rightJustifiedComponent={<NavbarRight />}
      />
    </Container>
  );
};

export default Navbar;
