import React from "react";
import { carrierLogo, BackgroundImage, LynxLogo } from "../assets";
import {
  Header,
  SubHeading,
  ContentCard,
  CardCarousel,
} from "../../../components";
import { Box } from "@mui/material";
import "./GettingStarted.scss";
import { GettingStartedCardDetails } from "./GettingStartedCardDetails";

const GettingStarted: React.FC = () => {
  const subContent = undefined;
  const cardCarosuel = (
    <CardCarousel
      cards={GettingStartedCardDetails.map((card, index) => (
        <ContentCard
          key={index}
          title={card.title}
          imageUrl={card.imageUrl}
          description={card.description}
          imageIndex={card.imageIndex}
        />
      ))}
    ></CardCarousel>
  );

  return (
    <>
      <Box className="getting-started-container">
        <Header
          logo={<img src={carrierLogo} alt="Logo" />}
          mainHeading="Ready, Set, Go!"
          mainParagraph="Carrier’s Transport Refrigeration API Solutions is easy to setup and lets you get started right away."
          subContent={subContent}
          headerBgImage={BackgroundImage}
          cardCarosuel={cardCarosuel}
        />
        <SubHeading
          logo={LynxLogo}
          heading="Interested in becoming a LYNX customer and enjoying the benefits of LYNX APIs?"
          btnText="Get in touch with a Carrier Champion"
          subText="Contact our local LYNX sales to hear more about the available LYNX API Services offering and pricing."
        />
      </Box>
    </>
  );
};

export default GettingStarted;
