import React from "react";
import { Box, Typography, Divider, Chip, Paper } from "@mui/material";
import { Props } from "../types";

const ApiDetails: React.FC<Props> = ({
  swaggerData,
  selectedPath,
  selectedSchema,
  schemas,
  url,
  getMethodColor,
}) => {
  const renderParameters = (parameters: any[]) => {
    return parameters.map((param: any, index: number) => (
      <Box
        key={index}
        sx={{
          marginBottom: 3,
          padding: "5px",
          borderBottom: "1px solid rgb(238, 238, 238)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginBottom: 2,
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            {param.name}:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {param.schema.type}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              fontSize: "12px",
              color: param.required ? "red" : "#555",
              backgroundColor: param.required ? "#ffecec" : "",
              borderRadius: "4px",
              padding: "2px 8px",
            }}
          >
            {param.required ? "required" : ""}{" "}
          </Typography>
        </Box>
        {/* Description */}
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ marginBottom: 2 }}
        >
          {param.description || "No description provided."}
        </Typography>
      </Box>
    ));
  };

  const renderRequestBody = (requestBody: any) => (
    <Paper sx={{ padding: 2, marginTop: 2 }}>
      <pre
        style={{
          backgroundColor: "#f4f4f4",
          padding: "12px",
          borderRadius: "4px",
          fontSize: "14px",
        }}
      >
        {JSON.stringify(requestBody, null, 2)}
      </pre>
    </Paper>
  );

  return (
    <Box
      sx={{
        width: "50%",
        padding: 2,
        overflowY: "auto",
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
      }}
    >
      {selectedPath ? (
        <Box>
          <Box sx={{ marginBottom: 3 }}>
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {selectedPath.path}
            </Typography>
            <Typography variant="body2" sx={{ color: "#555", marginTop: 1 }}>
              <Chip
                label={selectedPath.method.toUpperCase()}
                sx={{
                  marginRight: 1,
                  backgroundColor: getMethodColor(selectedPath.method),
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "10px",
                }}
              />
              {url} {selectedPath.path}
            </Typography>
          </Box>

          {/* API Description */}
          <Box sx={{ marginBottom: 3 }}>
            <Typography variant="body2" sx={{ lineHeight: 1.6 }}>
              {swaggerData.paths?.[selectedPath?.path]?.[selectedPath?.method]
                ?.description || "No description available."}
            </Typography>
          </Box>
          <Divider sx={{ marginBottom: 3 }} />

          {/* Parameters Section */}
          {(swaggerData?.paths?.[selectedPath.path]?.[selectedPath.method]
            ?.parameters?.length ?? 0) > 0 && (
            <Box>
              {swaggerData.paths?.[selectedPath?.path]?.[
                selectedPath?.method
              ]?.parameters?.some((param: any) => param.in === "path") && (
                <Box sx={{ marginBottom: 3 }}>
                  <Typography variant="h6">Path Parameters</Typography>
                  {renderParameters(
                    swaggerData.paths?.[selectedPath?.path]?.[
                      selectedPath?.method
                    ].parameters?.filter((param: any) => param.in === "path") ??
                      [],
                  )}
                </Box>
              )}

              {/* Query Parameters */}
              {swaggerData.paths?.[selectedPath?.path]?.[
                selectedPath?.method
              ]?.parameters?.some((param: any) => param.in === "query") && (
                <Box sx={{ marginBottom: 3 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", marginBottom: "5px" }}
                  >
                    Query Parameters
                  </Typography>
                  {(swaggerData.paths?.[selectedPath?.path]?.[
                    selectedPath?.method
                  ]?.parameters?.filter((param: any) => param.in === "query")
                    .length ?? 0) > 0 ? (
                    <Paper sx={{ padding: 2, backgroundColor: "#fff" }}>
                      {renderParameters(
                        swaggerData.paths?.[selectedPath?.path]?.[
                          selectedPath?.method
                        ]?.parameters?.filter(
                          (param: any) => param.in === "query",
                        ) ?? [],
                      )}
                    </Paper>
                  ) : (
                    <Typography variant="body2" sx={{ color: "#777" }}>
                      No query parameters available for this endpoint.
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}

          {/* Request Body Section */}
          {swaggerData.paths?.[selectedPath?.path]?.[selectedPath?.method]
            ?.requestBody && (
            <Box sx={{ marginBottom: 3 }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                Request Body
              </Typography>
              {renderRequestBody(
                swaggerData.paths?.[selectedPath?.path]?.[selectedPath?.method]
                  ?.requestBody,
              )}
            </Box>
          )}

          {/* Combination of Path Parameters & Request Body */}
          {swaggerData.paths?.[selectedPath?.path]?.[
            selectedPath?.method
          ]?.parameters?.some((param: any) => param.in === "path") &&
            swaggerData.paths?.[selectedPath?.path]?.[selectedPath?.method]
              ?.requestBody && (
              <Box
                sx={{
                  marginTop: 2,
                  padding: 2,
                  backgroundColor: "#ffeccc",
                  borderRadius: 2,
                }}
              >
                <Typography variant="body2">
                  <strong>Note:</strong> This API requires both{" "}
                  <em>Path Parameters</em> and a <em>Request Body</em>.
                </Typography>
              </Box>
            )}
        </Box>
      ) : selectedSchema ? (
        <Box>
          <Typography variant="h6">Schema: {selectedSchema}</Typography>
          <Paper sx={{ padding: 2, backgroundColor: "#f4f4f4", marginTop: 2 }}>
            <pre style={{ fontSize: "14px" }}>
              {JSON.stringify(schemas[selectedSchema], null, 2)}
            </pre>
          </Paper>
        </Box>
      ) : (
        <Typography variant="body2">
          Select an API path or schema to view details.
        </Typography>
      )}
    </Box>
  );
};

export default ApiDetails;
