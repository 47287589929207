import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Paper,
} from "@mui/material";
import { Parameter, TryApiPanelProps } from "../types";

const TryApiPanel: React.FC<TryApiPanelProps> = ({
  selectedPath,
  swaggerData,
  formData,
  handleInputChange,
  handleSubmitWithLoading,
  apiResponseData,
  apiResponses,
  loading,
}) => {
  return (
    <Box
      sx={{
        width: "30%",
        padding: 3,
        borderLeft: "1px solid #ccc",
        overflowY: "auto",
        backgroundColor: "#f0f0f0",
      }}
    >
      {selectedPath ? (
        <Box>
          {/* Try API Section */}
          <Box mb={3}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Try this API
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontSize: "14px",
                marginBottom: "4px",
              }}
            >
              Request parameters
            </Typography>
            <form onSubmit={handleSubmitWithLoading}>
              {(
                swaggerData.paths[selectedPath.path][selectedPath.method]
                  ?.parameters || []
              ).map((param: Parameter, index: number) => (
                <Box key={index} mb={2}>
                  <Typography variant="body1" gutterBottom>
                    {param.name}{" "}
                    {param.required && (
                      <Typography
                        component="span"
                        sx={{ color: "red", fontWeight: "normal" }}
                      >
                        *
                      </Typography>
                    )}
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    name={param.name}
                    placeholder={param.schema.type}
                    value={formData[param.name] || ""}
                    onChange={handleInputChange}
                    sx={{
                      backgroundColor: "white",
                    }}
                  />
                </Box>
              ))}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  mt: 2,
                  fontWeight: "bold",
                  textTransform: "none",
                  backgroundColor: "#02155f",
                  color: "white",
                }}
              >
                EXECUTE
              </Button>
            </form>
          </Box>

          {/* API Response Section */}
          {apiResponseData && (
            <Box mt={3}>
              {loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="300px"
                >
                  <CircularProgress />
                </Box>
              ) : apiResponses[selectedPath.path] ? (
                <Paper
                  sx={{
                    height: 300,
                    overflowY: "auto",
                    padding: 2,
                    backgroundColor: "#f8f9fa",
                    border: "1px solid #ddd",
                  }}
                >
                  {/* Status Section */}
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color={
                      apiResponses[selectedPath.path].status === 200
                        ? "green"
                        : "red"
                    }
                    gutterBottom
                  >
                    Status: {apiResponses[selectedPath.path].status}
                  </Typography>

                  {/* Data Section */}
                  <Box>
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {JSON.stringify(
                        apiResponses[selectedPath.path].data,
                        null,
                        2,
                      )}
                    </pre>
                  </Box>
                </Paper>
              ) : null}
            </Box>
          )}
        </Box>
      ) : (
        <Typography>Select an API path to try it</Typography>
      )}
    </Box>
  );
};

export default TryApiPanel;
