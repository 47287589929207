import React from "react";
import { Typography } from "@carrier-io/air-react/";
import Box from "@carrier-io/air-react/Box";
import Button from "@carrier-io/air-react/Button";
import ContactForm from "../ContactForm/ContactForm";
import { useState } from "react";
import "./SubHeader.scss";


interface SubHeaderProps {
  logo?: string;
  heading?: string;
  btnText?: string;
  subText?: string;
}

const SubHeading: React.FC<SubHeaderProps> = ({
  logo,
  heading,
  btnText,
  subText,
}) => {
  const [isOpen,setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    
    const handleClose = () => {
        setOpen(false)
    }
  return (
    <Box className="sub-header">
      <Box>
        {logo && (
          <img className="sub-header-logo" src={logo} alt="subheader logo" />
        )}
      </Box>
      <Typography variant="h4">{heading}</Typography>
      {btnText && (
        <Button variant="contained" size="large" onClick={handleOpen}>
          {btnText}
        </Button>
      )}
      <ContactForm open={isOpen} onClose={handleClose}/>
      {subText && <Typography variant="h5">{subText}</Typography>}
    </Box>
  );
};

export default SubHeading;
