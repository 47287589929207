import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ApiDisplayProps } from "../types";

const ApiDisplay: React.FC<ApiDisplayProps> = ({
  groupedByVersion,
  expandedSections,
  toggleSection,
  selectedPath,
  handlePathSelect,
  schemas,
  selectedSchema,
  handleSchemaSelect,
  getMethodColor,
}) => {
  return (
    <div
      style={{
        borderRight: "1px solid rgb(204, 204, 204)",
        maxHeight: "100vh",
        overflowY: "auto",
      }}
    >
      {/* Grouped APIs by Version */}
      {Object.entries(groupedByVersion).map(([version, paths]) => (
        <Accordion
          key={version}
          expanded={expandedSections[version]}
          onChange={() => toggleSection(version)}
          sx={{ marginBottom: 2 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              backgroundColor: "rgb(244, 244, 244)",
              border: "none",
              outline: "none",
              boxShadow: "none",
            }}
          >
            <Typography variant="h6">/{version}/</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {paths.map((path, index) => (
                <ListItemButton
                  key={index}
                  onClick={() => handlePathSelect(path)}
                  sx={{
                    padding: "8px",
                    // backgroundColor: 'transparent',
                    borderRadius: 2,
                    backgroundColor:
                      selectedPath?.path === path.path &&
                      selectedPath?.method === path.method
                        ? "#f0f0f0"
                        : "transparent",
                  }}
                >
                  <ListItemText
                    primary={path.path
                      .replace(/^\/(v\d+)\//, "")
                      .replace(/^\//, "")}
                  />
                  <Chip
                    label={path.method.toUpperCase()}
                    sx={{
                      marginLeft: 2,
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "12px",
                      backgroundColor: getMethodColor(path.method),
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}

      {/* Display schemas with expandable/collapsible section */}
      <Accordion
        expanded={expandedSections["schemas"]}
        onChange={() => toggleSection("schemas")}
        sx={{ marginBottom: 2 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: "rgb(244, 244, 244)",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Typography variant="h6">Schemas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {Object.keys(schemas).map((schemaName, index) => (
              <ListItemButton
                key={index}
                onClick={() => handleSchemaSelect(schemaName)}
                style={{
                  backgroundColor:
                    selectedSchema === schemaName ? "#f0f0f0" : "transparent",
                }}
              >
                <ListItemText primary={schemaName} />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ApiDisplay;
