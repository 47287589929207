import React, { useEffect, useState } from "react";
import rightButton from "../../assets/right-button.svg";
import leftButton from "../../assets/left-button.svg";
import "./CardCarousel.scss";
import { Box } from "@mui/material";

interface CarouselProps {
  cards: React.ReactNode[];
}

const CardCarousel: React.FC<CarouselProps> = ({ cards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Handlers for navigation
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cards.length - 1 : prevIndex - 1,
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === cards.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const goToIndex = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel-container">
      <div className="carousel-card">{cards[currentIndex]}</div>
      <Box className="carousel-arrows">
        <img
          className="arrow left-arrow"
          src={leftButton}
          onClick={goToPrevious}
        ></img>
        <div className="carousel-dots">
          {cards.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? "active" : ""}`}
              onClick={() => goToIndex(index)}
            ></span>
          ))}
        </div>

        <img
          className="arrow right-arrow"
          src={rightButton}
          onClick={goToNext}
        />
      </Box>
    </div>
  );
};

export default CardCarousel;
