import React from "react";
import { Typography } from "@carrier-io/air-react";
import Box from "@carrier-io/air-react/Box";
import CardIndexCircle from "./CardIndexCircle";
import "./ContentCard.scss";
import Button from "@carrier-io/air-react/Button";

interface ApiSectionProps {
  title?: string;
  subtitle?: string;
  description?: string;
  buttonText?: string;
  imageUrl?: string;
  iconUrl?: string;
  imageIndex?: number;
}

const ContentCard: React.FC<ApiSectionProps> = ({
  title,
  subtitle,
  description,
  buttonText,
  imageUrl,
  iconUrl,
  imageIndex,
}) => {
  return (
    <Box className="content-card">
      {title && (
        <Typography variant="h6">
          {iconUrl ? (
            <img src={iconUrl} />
          ) : (
            <CardIndexCircle cardIndex={imageIndex}></CardIndexCircle>
          )}
          {title}
        </Typography>
      )}
      {imageUrl && <img className="card-img" src={imageUrl} />}
      {subtitle && <Typography variant="body3Bold">{subtitle}</Typography>}
      {description && <Typography variant="body2"> {description}</Typography>}
      {buttonText && (
        <Button variant="outlined" sx={{}}>
          {" "}
          {buttonText}{" "}
        </Button>
      )}
    </Box>
  );
};

export default ContentCard;
