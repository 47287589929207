import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import ProductPage from "./components/ProductPage";
import Login from "./components/Login";
import CustomSwagger from "./components/CustomSwagger";
import NotFoundPage from "./components/NotFoundPage";
import ApiSwagger from "./components/ApiSwagger";
import LandingPage from "./features/LandingPage/LandingPage/LandingPage";

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/products" element={<ProductPage />} />
        <Route
          path="/products/tti-one-way"
          element={
            <CustomSwagger
              url="/api-specs/external-rest-api.yml"
              apiName="TTI One Way API"
            />
          }
        />
        <Route
          path="/lynx-api"
          element={
            <ApiSwagger
              yamlFilePath="/api-specs/external-rest-api.yml"
              apiName="Carrier LYNX Developer APIs"
            />
          }
        />
        <Route path="*" element={<NotFoundPage />} />{" "}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
