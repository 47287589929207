import React from "react";
import Box from "@carrier-io/air-react/Box";
import Button from "@carrier-io/air-react/Button";
import { Typography } from "@carrier-io/air-react";
import "./Header.scss";

interface SubContentItem {
  iconUrl: string;
  heading: string;
  paragraph: string;
}

interface HeaderProps {
  logo: React.ReactNode;
  mainHeading: string;
  mainParagraph: string;
  buttonText?: string;
  subContent?: SubContentItem[];
  headerStaticImage?: string;
  headerBgImage: string;
  cardCarosuel?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({
  logo,
  mainHeading,
  mainParagraph,
  buttonText,
  subContent = [],
  headerStaticImage,
  headerBgImage,
  cardCarosuel,
}) => {
  return (
    <header className="header">
      <Box className="header-content">
        <Box className="main-content">
          <Typography variant="h1">{mainHeading}</Typography>
          <Typography variant="h6">{mainParagraph}</Typography>
        </Box>
        {buttonText && (
          <Button
            variant="contained"
            sx={{ width: 159, height: 48, background: "#0038FF" }}
            disableRipple
          >
            {buttonText}
          </Button>
        )}
        {subContent.length > 0 && (
          <div className="sub-content">
            {subContent.map((content, index) => (
              <div key={index} className="sub-section">
                <h3>
                  {content.iconUrl && (
                    <img
                      className="sub-section-img"
                      src={content.iconUrl}
                      alt="sub-section-img"
                    />
                  )}
                  {content.heading}
                </h3>
                <p>{content.paragraph}</p>
              </div>
            ))}
          </div>
        )}
        <div className="logo">{logo}</div>
      </Box>
      <Box className="header-img">
        {headerStaticImage && (
          <img
            className="header-static-img"
            src={headerStaticImage}
            alt="Header static visual"
          />
        )}
        {headerBgImage && (
          <img className="header-bg-img" src={headerBgImage} alt="bg visual" />
        )}
        {cardCarosuel}
      </Box>
    </header>
  );
};

export default Header;
