import React from 'react'
import Box from '@carrier-io/air-react/Box'
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@carrier-io/air-react/TextField'
import Button from '@carrier-io/air-react/Button'

import './ContactForm.scss'
import { Typography } from '@carrier-io/air-react';
interface contactFormProps{
    open: boolean;
    onClose: () => void;
}
const ContactForm:React.FC<contactFormProps> = ({open,onClose}) => {
    
  return (
    <Dialog open={open} onClose={onClose}>
    <Box className='request-form'>
        <Box className='request-form-title'>
            <Typography variant='h6'> Contact request </Typography>
            <IconButton onClick={onClose}> <CloseIcon/></IconButton>
        </Box>
        <Box className='request-form-content'>
            <Box className='request-form-content-subtitle'> Fill out the contact form and a Customer Champion will get in touch. </Box>
            <Box className='request-form-content-name'>
                <TextField className='request-form-content-name-first' required label="First Name"/>
                <TextField className='request-form-content-name-last' required label="Last Name"/>
            </Box>
            <Box className='request-form-content-info'>
                <TextField className='request-form-content-info-company' required label="Company"/>
                <TextField className='request-form-content-info-email' required label="Email"/>
                <TextField className='request-form-content-info-message' label="Message (optional)"/>
            </Box>
            <Box className='request-form-content-footer'>
                <Box className='request-form-content-footer-buttons'>
                    <Button className='request-form-content-footer-buttons-cancel' variant='text' disableTouchRipple onClick={onClose}>Cancel</Button>
                    <Button variant='outlined'>Submit</Button>
                </Box>
            </Box>
        </Box>
    </Box>
    </Dialog>
  )
}

export default ContactForm