import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { ApiAuthDialogProps } from "../types";

const ApiAuthDialog: React.FC<ApiAuthDialogProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [localApiKey, setLocalApiKey] = useState<string>("");

  const handleSave = () => {
    onSubmit(localApiKey);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Enter API Key</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          label="x-lynx-api-key"
          placeholder="Enter API Key"
          value={localApiKey}
          onChange={(e) => setLocalApiKey(e.target.value)}
          sx={{ marginTop: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{
            backgroundColor: "#011666",
            fontWeight: "bold",
            color: "white",
          }}
        >
          AUTHORIZE
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClose}
          sx={{
            border: "1px solid #011666",
            fontWeight: "bold",
            color: "#011666",
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApiAuthDialog;
