import React, { useEffect, useState } from "react";
import * as yaml from "js-yaml";
import ApiDisplay from "./ApiDisplay";
import ApiDetails from "./ApiDetails";
import TryApiPanel from "./TryApiPanel";
import ApiAuthDialog from "./ApiAuthDialog";
import ApiHeader from "./ApiHeader";
import { SwaggerPath, SwaggerProps } from "../types";
import "../styles/ApiSwagger.scss";

const SwaggerUI: React.FC<SwaggerProps> = ({ yamlFilePath, apiName }) => {
  const [swaggerData, setSwaggerData] = useState<any | null>(null);
  const [selectedPath, setSelectedPath] = useState<SwaggerPath | null>(null);
  const [selectedSchema, setSelectedSchema] = useState<string | null>(null);
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});
  const [error, setError] = useState<string | null>(null);
  const [apiKey, setApiKey] = useState<string>("");
  const [apiResponses, setApiResponses] = useState<
    Record<string, { status: number; data: any }>
  >({});
  const [queryParams, setQueryParams] = useState<Record<string, string>>({});
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const [apiResponseData, setResponseData] = useState<any>(null);

  // Load and parse YAML to JSON
  useEffect(() => {
    const loadYamlFile = async () => {
      try {
        const response = await fetch(yamlFilePath);
        const yamlContent = await response.text();
        const jsonData = yaml.load(yamlContent);
        setSwaggerData(jsonData);
      } catch (err) {
        console.error("Failed to load or parse YAML file:", err);
        setError("Failed to load or parse the YAML file.");
      }
    };

    loadYamlFile();
  }, [yamlFilePath]);

  useEffect(() => {
    setResponseData(null);
    setLoading(false);
    setFormData({});
  }, [selectedPath]);

  // Toggle sections (expand/collapse)
  const toggleSection = (section: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  // Handle API Path Selection
  const handlePathSelect = (path: SwaggerPath) => {
    setSelectedPath(path);
    setSelectedSchema(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setQueryParams((prev) => ({ ...prev, [name]: value }));
  };

  // Handle Schema Selection
  const handleSchemaSelect = (schema: string) => {
    setSelectedSchema(schema);
    setSelectedPath(null);
  };

  const handleAuthorizeClick = () => {
    setIsAuthModalOpen(true);
  };

  const getMethodColor = (method: string) => {
    switch (method.toLowerCase()) {
      case "get":
        return "#0a9970";
      case "delete":
        return "red";
      case "post":
        return "#0071c2";
      case "put":
        return "#ffc379";
      default:
        return "gray";
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log("loading :: ", loading);
    e.preventDefault();

    if (!selectedPath || !swaggerData) {
      alert("Invalid path or Swagger data.");
      return;
    }

    const baseUrl = swaggerData.servers?.[0]?.url || "";
    const endpoint = `${baseUrl}${selectedPath.path}`;
    const urlWithParams = new URL(endpoint);

    Object.entries(queryParams).forEach(([key, value]) => {
      if (value.trim() !== "") {
        urlWithParams.searchParams.append(key, value);
      }
    });

    const params =
      selectedPath.method.toUpperCase() !== "GET"
        ? JSON.stringify(queryParams)
        : undefined;

    try {
      const response = await fetch(urlWithParams.toString(), {
        method: selectedPath.method.toUpperCase(),
        headers: {
          "Content-Type": "application/json",
          "x-lynx-api-key": apiKey,
        },
        body: params,
      });

      const responseData = await response.json();

      // Update the state only for the current API path
      setApiResponses((prev) => ({
        ...prev,
        [selectedPath.path]: {
          status: response.status,
          data: responseData,
        },
      }));
      setResponseData(responseData);
    } catch (error: any) {
      setApiResponses((prev) => ({
        ...prev,
        [selectedPath.path]: {
          status: 500,
          data: { error: error.message },
        },
      }));
      setResponseData({
        status: 500,
        data: { error: error.message },
      });
    }
  };

  const handleSubmitWithLoading = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    setLoading(true);

    try {
      await handleSubmit(e);
    } catch (error) {
      console.error("Error during API call", error);
    } finally {
      setLoading(false);
    }
  };
  if (error) {
    return <div style={{ color: "red" }}>{error}</div>;
  }

  if (!swaggerData) {
    return <div>Loading...</div>;
  }

  // Group APIs by version (e.g., "v1", "v2")
  const groupedByVersion: { [version: string]: SwaggerPath[] } = {};

  Object.entries(swaggerData.paths || {}).forEach(([path, methods]: any) => {
    const versionMatch = path.match(/^\/(v\d+)\//); // Match version prefix (e.g., "/v1/")
    const version = versionMatch ? versionMatch[1] : "default";

    Object.entries(methods).forEach(([method, details]: any) => {
      const apiDetails = {
        path,
        method,
        description: details.summary || "No description available",
      };

      if (!groupedByVersion[version]) {
        groupedByVersion[version] = [];
      }
      groupedByVersion[version].push(apiDetails);
    });
  });

  const schemas = swaggerData.components?.schemas || {};
  const url = swaggerData.servers[0].url;

  return (
    <>
      <ApiHeader
        onAuthorizeClick={handleAuthorizeClick}
        // onDownloadSpecClick={handleDownloadSpec}
      />

      <ApiAuthDialog
        open={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
        onSubmit={(key: string) => {
          setApiKey(key);
          setIsAuthModalOpen(false);
        }}
      />
      <div style={{ display: "flex", height: "100vh" }}>
        {/* Left Panel */}
        <ApiDisplay
          groupedByVersion={groupedByVersion}
          expandedSections={expandedSections}
          toggleSection={toggleSection}
          selectedPath={selectedPath}
          handlePathSelect={handlePathSelect}
          schemas={schemas}
          selectedSchema={selectedSchema}
          handleSchemaSelect={handleSchemaSelect}
          getMethodColor={getMethodColor}
        />

        {/* Middle Panel: API Details */}

        <ApiDetails
          swaggerData={swaggerData}
          selectedPath={selectedPath}
          selectedSchema={selectedSchema}
          schemas={schemas}
          url={url}
          getMethodColor={getMethodColor}
        />

        {/* Right Panel: Try API */}
        {selectedSchema === null && (
          <TryApiPanel
            selectedPath={selectedPath}
            swaggerData={swaggerData}
            formData={formData}
            handleInputChange={handleInputChange}
            handleSubmitWithLoading={handleSubmitWithLoading}
            apiResponseData={apiResponseData}
            apiResponses={apiResponses}
            loading={loading}
          />
        )}
      </div>
    </>
  );
};

export const CustomSwagger: React.FC<SwaggerProps> = (props) => {
  return <SwaggerUI {...props} />;
};

export default CustomSwagger;
