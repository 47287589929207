import { register, activate, signin, access } from "../assets";
export const GettingStartedCardDetails = [
  {
    title: "Register to LYNX API Portal",
    subtitle: "Streamline your international fleet operations",
    description:
      "Sign up for a LYNX API Portal account to explore the technical details of LYNX APIs and try them out in our Sandbox. You can get to the registration form by clicking on the Register button in the top right corner.",
    buttonText: "Explore TTI API Products",
    cardData: [],
    imageIndex: 1,
    imageUrl: register,
  },
  {
    title: "Activate your account",
    subtitle: "Streamline your international fleet operations",
    description:
      "You will receive an activation link via email. Follow the instructions to activate our account.",
    buttonText: "Explore TTI API Products",
    cardData: [],
    imageIndex: 2,
    imageUrl: activate,
  },
  {
    title: "Sign into your account",
    subtitle: "Streamline your international fleet operations",
    description:
      "Take advantage of our API documentation, developer guides and virtual building to create and test your smart solutions.",
    buttonText: "Explore TTI API Products",
    cardData: [],
    imageIndex: 3,
    imageUrl: signin,
  },
  {
    title: "Access to LYNX equipment & data",
    subtitle: "Streamline your international fleet operations",
    description:
      "LYNX API Services add value to LYNX equipment and maintenance service. Access to LYNX data or equipment through LYNX APIs is available for LYNX maintenance service customers with LYNX API Services contract.",
    buttonText: "Explore TTI API Products",
    cardData: [],
    imageIndex: 4,
    imageUrl: access,
  },
];
